body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#FFFFFF";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'AgroAdapt';
  color: #246046;
  width: 100%;
  min-width: 800px;
}
.content {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 100px auto 100px;
  grid-template-rows: 100px auto 100px;  
  width: 100%;
  min-width: 1000px;
  height: calc(100vh - 10vh - 35px);
  min-height: 400px;  
  box-sizing: border-box;
}
.cell {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
}
.cell img {
  max-width: 100%;
  max-height: 100%;
}
.cell2hmerge {
  grid-column: span 2;
}
.cell3hmerge {
  grid-column: span 3;
}
.cell2vmerge {
  grid-row: span 2;
}
.cell3vmerge {
  grid-row: span 3;
}
.header-row {  
  padding: 0;
  margin: 0;
  margin-bottom: 35px;
  display: grid;
  grid-template-columns: 500px 1fr 500px;
  width: 100%;
  min-width: 1000px;
  height: 10vh;
  min-height: 50px;
  align-items: start;
}
