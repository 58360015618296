@font-face {
  font-family: "AgroAdapt";
  src: url("fonts/Figtree-Light.ttf") format("truetype");
}

.Timer {
  text-align: center;
}

.Timer-logo {
  height: 5%;
  pointer-events: none;
}

.Timer-header {
  background-color: #282c34;
  padding: 2px;
  color: white;
}

.Timer-link {
  color: #61dafb;
}